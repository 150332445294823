































































































































































































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, PropSync, Vue } from 'vue-property-decorator'
import { communityOwnerBoardController } from '../../modules/common/components/section/owner-section-controller'
import Avatar from '@/modules/community/common/components/avatar.vue'
import AccountDetailCard from '../auth/account-detail-card.vue'

@Observer
@Component({
  components: {
    Avatar,
    AccountDetailCard,
    'connect-wallet': () => import('./connect-wallet.vue'),
    notification: () => import('../notification/notification.vue'),
  },
})
export default class NavigationBar extends Vue {
  @PropSync('drawer') drawerDialog!: boolean
  showAccountDetail = false
  controller = communityOwnerBoardController
  wallet = walletStore

  get isCommunityRoute() {
    const routeName = (this.$route?.matched || [])[0]?.name
    return routeName === 'Community'
  }

  openEarnTab() {
    // if (!this.wallet.walletConnected) {
    //   this.wallet.changeOpenEarnConnectWallet(true)
    // } else
    this.$router.replace('/earn')
  }

  drawerStatus() {
    this.drawerDialog = !this.drawerDialog
  }

  goToHome() {
    if (this.isCommunityRoute) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      this.$router.push('/community')
    }
  }

  get path() {
    if (!this.$route.matched.length) {
      return ''
    }
    return this.$route.path
  }

  get routeName() {
    return this.$route.name
  }

  heightMainAppBarDesktop = 75
  heightMainAppBarMobile = 55
  heightSubAppBar = 55

  get heightMainAppbar() {
    return this.$vuetify.breakpoint.width >= 1280 ? this.heightMainAppBarDesktop : this.heightMainAppBarMobile
  }

  get heightAppbar() {
    return this.showSubMenuCommuninty ? this.heightMainAppbar + this.heightSubAppBar : this.heightMainAppbar
  }

  get showSubMenuCommuninty() {
    return (
      this.isCommunityRoute &&
      this.routeName !== 'Trending-No-Auth' &&
      this.routeName !== 'Post-Detail' &&
      this.routeName !== 'Post List By Category'
    )
  }
}
