




































import Avatar from '@/modules/community/common/components/avatar.vue'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    Avatar,
  }
})
export default class extends Vue {
  walletStore = walletStore

  async copyAddress() {
    if (this.walletStore.account) {
      await navigator.clipboard.writeText(this.walletStore.account)
    }
  }
}
